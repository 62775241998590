import styled, { css } from "styled-components";
import { color, layout, space, typography } from "styled-system";

// Theme with font.primary/secondary etc.
export const Heading = styled.h1`
  ${typography};
  ${space};
  ${color}
  ${layout}
  text-transform: ${(props) => props.textTransform};
  letter-spacing: 0.2rem;

  ${({ stroke }) =>
    stroke &&
    css`
      color: white;
      -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: white;
    `};
`;
