import styled from "styled-components";
import { border, color, flexbox, layout, space, typography } from "styled-system";

// Theme with font.primary/secondary etc.
export const Li = styled.li`
  ${typography};
  ${space}
  ${border}
  ${color}
  ${layout}
  ${flexbox}
`;
